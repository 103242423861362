import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

// Firebase configuration (replace with your own Firebase project config)
const firebaseConfig = {
  apiKey: "AIzaSyCgCKFrcdFxQ9pkuqiJZpu4FMddWFm2xx8",
  authDomain: "devil-rank.firebaseapp.com",
  databaseURL: "https://devil-rank-default-rtdb.firebaseio.com",
  projectId: "devil-rank",
  storageBucket: "devil-rank.appspot.com",
  messagingSenderId: "741440117277",
  appId: "1:741440117277:web:3441be6ea8baa16143c4f4",
  measurementId: "G-ZRD6CD4KMN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export default function Support() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await addDoc(collection(firestore, "support"), {
        email: email,
        message: message,
        timestamp: serverTimestamp(),
      });
      console.log("Document successfully written!");
      setSubmitted(true);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1>App Support</h1>
          <p>
            If you're experiencing issues with our app or have any questions,
            please contact us directly using the form below.
          </p>

          {submitted ? (
            <div className="alert alert-success" role="alert">
              Thank you for contacting us. We will get back to you shortly.
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
