import React from "react";
import Navigation from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

function Bootstrap() {
  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
}

export default Bootstrap;
