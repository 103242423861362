import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Privacy from "./pages/Privacy";
import Support from "./pages/Support";
import Bootstrap from "./components/Bootstrap";
function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes Bootstrap */}
        <Route element={<Bootstrap />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/support" element={<Support />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
