export default function Footer() {
  return (
    <footer class="bg-black text-center py-5">
      <div class="container px-5">
        <div class="text-white-50 small">
          <div class="mb-2">
            {/*&copy;*/}DevilRank 2024. All Rights Reserved.
          </div>
          <a href="/privacy">Privacy</a>
          {/* <span class="mx-1">&middot;</span> */}
          {/* <a href="#!">Terms</a> */}
          {/* <span class="mx-1">&middot;</span> */}
          {/* <a href="#!">FAQ</a> */}
        </div>
      </div>
    </footer>
  );
}
