import React from "react";
// import { useAuth } from "../utils/AuthContext";
import Logo from "../static/img/logo.png";
// import User from "../static/img/user.png";
import { NavLink } from "react-router-dom"; // Import NavLink

const Navigation = () => {
  return (
    <>
      <style>
        {`
            @media (min-width: 992px) {
              .navbar .nav-item.dropdown:hover .dropdown-menu {
                  display: block !important;
                  right: 0;
                  left: auto;
                  margin-top: 0px;
              }
              .navbar-nav .nav-link {
                  margin-right: 10px; /* Adjust spacing here */
              }
            }
        `}
      </style>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container px-5">
          <NavLink className="navbar-brand" to="/">
            <img
              className="image"
              width="50px"
              height="100%"
              style={{
                maxHeight: "50px",
                marginRight: "10px",
                marginBottom: "10px",
                // filter: "drop-shadow(0px 0px 2px #fff)",
              }}
              src={Logo}
              alt="Devil Rank Logo"
            />
            Devil Rank
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/support">
                  Support
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/privacy">
                  Privacy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
